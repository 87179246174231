/* eslint-disable max-len */
// https://services.mobile.de/manual/changelog.html
// https://www.eautoseller.de/doku/mobilede_csv_api_ext.html

import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
  Grid2, Typography, Box, Paper,
} from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'next-i18next';
import NextImage from '@components/atoms/Image2';
import isNil from 'lodash/isNil';

export default function ConsumptionEmissionsNotice(props) {
  const {
    variant = 'compact',
    car,
  } = props;

  if (!isEmissionsDisplayRequired(car)) {
    return null;
  }

  return variant === 'compact' ? (
    <CompactConsumptionEmissions car={car} />
  ) : (
    <ExtendedConsumptionEmissions car={car} />
  );
}

ConsumptionEmissionsNotice.propTypes = {
  variant: PropTypes.oneOf(['compact', 'extended']),
};

function CompactConsumptionEmissions(props) {
  const { car } = props;
  const { i18n } = useTranslation();
  let { fuelType } = car.attributes ?? car;
  const {
    isPluginHybrid,
    emissionsCombinedCo2,
    emissionsCombinedCo2Weighted,
    emissionsCombinedCo2Class,
    emissionsCombinedCo2ClassWeighted,
    emissionsDischargedCo2Class,
    consumptionsWeightedCombinedFuel,
    consumptionsFuelCombined,
    consumptionsWeightedCombinedPower,
    consumptionsPowerCombined,
    consumptionsFuelWithDischargedBatteryCombined,
  } = car.attributes ?? car;
  const parts = [];

  if (isPluginHybrid) {
    fuelType = 'plugin-hybrid';
  }

  switch (fuelType) {
    case 'gasoline':
    case 'diesel':
    case 'lpg':
    case 'hybrid':
    case 'hybridDiesel':
      if (!isNil(consumptionsFuelCombined)) {
        parts.push(
          <span>
            <strong>
              {formatNumber(consumptionsFuelCombined, i18n.language)}
            </strong>
            {' '}
            l/100 km (komb.)
          </span>,
        );
      }

      if (!isNil(emissionsCombinedCo2)) {
        parts.push(
          <span>
            <strong>
              {emissionsCombinedCo2}
            </strong>
            {' '}
            g CO
            <sub><small>2</small></sub>
            /100 km (komb.)
          </span>,
        );
      }

      if (emissionsCombinedCo2Class) {
        parts.push(
          <span>
            CO
            <sub><small>2</small></sub>
            -Klasse
            {' '}
            <strong>
              {emissionsCombinedCo2Class}
            </strong>
            {' '}
            (komb.)
          </span>,
        );
      }
      break;
    case 'naturalGas':
      if (!isNil(consumptionsFuelCombined)) {
        parts.push(
          <span>
            <strong>
              {formatNumber(consumptionsFuelCombined, i18n.language)}
            </strong>
            {' '}
            kg/100 km (komb.)
          </span>,
        );
      }

      if (!isNil(emissionsCombinedCo2)) {
        parts.push(
          <span>
            <strong>
              {emissionsCombinedCo2}
            </strong>
            {' '}
            g CO
            <sub><small>2</small></sub>
            /100 km (komb.)
          </span>,
        );
      }

      if (emissionsCombinedCo2Class) {
        parts.push(
          <span>
            CO
            <sub><small>2</small></sub>
            -Klasse
            {' '}
            <strong>
              {emissionsCombinedCo2Class}
            </strong>
            {' '}
            (komb.)
          </span>,
        );
      }
      break;
    case 'plugin-hybrid':
      if (!isNil(consumptionsWeightedCombinedFuel)) {
        parts.push(
          <span>
            <strong>
              {formatNumber(consumptionsWeightedCombinedFuel, i18n.language)}
            </strong>
            {' '}
            l/100 km (gewichtet, komb.)
          </span>,
        );
      }

      if (!isNil(consumptionsFuelWithDischargedBatteryCombined)) {
        parts.push(
          <span>
            <strong>
              {formatNumber(consumptionsFuelWithDischargedBatteryCombined, i18n.language)}
            </strong>
            {' '}
            l/100 km (entl. Batterie, komb.)
          </span>,
        );
      }

      if (!isNil(consumptionsWeightedCombinedPower)) {
        parts.push(
          <span>
            <strong>
              {formatNumber(consumptionsWeightedCombinedPower, i18n.language)}
            </strong>
            {' '}
            kWh/100 km (gewichtet, komb.)
          </span>,
        );
      }

      if (!isNil(emissionsCombinedCo2Weighted)) {
        parts.push(
          <span>
            <strong>
              {emissionsCombinedCo2Weighted}
            </strong>
            {' '}
            g CO
            <sub><small>2</small></sub>
            /100 km (gewichtet, komb.)
          </span>,
        );
      }

      if (emissionsCombinedCo2ClassWeighted) {
        parts.push(
          <span>
            CO
            <sub><small>2</small></sub>
            -Klasse
            {' '}
            <strong>
              {emissionsCombinedCo2ClassWeighted}
            </strong>
            {' '}
            (gewichtet, komb.)
          </span>,
        );
      }

      if (emissionsDischargedCo2Class) {
        parts.push(
          <span>
            CO
            <sub><small>2</small></sub>
            -Klasse
            {' '}
            <strong>
              {emissionsDischargedCo2Class}
            </strong>
            {' '}
            (entl. Batterie, komb.)
          </span>,
        );
      }
      break;
    case 'electric':
      if (!isNil(consumptionsPowerCombined)) {
        parts.push(
          <span>
            <strong>
              {formatNumber(consumptionsPowerCombined, i18n.language)}
            </strong>
            {' '}
            kWh/100 km (komb.)
          </span>,
        );
      }

      if (!isNil(emissionsCombinedCo2)) {
        parts.push(
          <span>
            <strong>
              {emissionsCombinedCo2}
            </strong>
            {' '}
            g CO
            <sub><small>2</small></sub>
            /100 km (komb.)
          </span>,
        );
      }

      if (emissionsCombinedCo2Class) {
        parts.push(
          <span>
            CO
            <sub><small>2</small></sub>
            -Klasse
            {' '}
            <strong>
              {emissionsCombinedCo2Class}
            </strong>
            {' '}
            (komb.)
          </span>,
        );
      }
      break;
    case 'hydrogen':
      if (!isNil(consumptionsFuelCombined)) {
        parts.push(
          <span>
            <strong>
              {formatNumber(consumptionsFuelCombined, i18n.language)}
            </strong>
            {' '}
            kg/100 km (komb.)
          </span>,
        );
      }

      if (!isNil(emissionsCombinedCo2)) {
        parts.push(
          <span>
            <strong>
              {emissionsCombinedCo2}
            </strong>
            {' '}
            g CO
            <sub><small>2</small></sub>
            /100 km (komb.)
          </span>,
        );
      }

      if (emissionsCombinedCo2Class) {
        parts.push(
          <span>
            CO
            <sub><small>2</small></sub>
            -Klasse
            {' '}
            <strong>
              {emissionsCombinedCo2Class}
            </strong>
            {' '}
            (komb.)
          </span>,
        );
      }
      break;
    default:
      break;
  }

  if (parts.length === 0) {
    return null;
  }

  return (
    <>
      <sup><small>I.</small></sup>
      {' '}
      {parts.map((p, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <Typography component="span" variant="body2">
            {p}
          </Typography>
          {index < parts.length - 1 && (
            <Typography component="span" variant="body2">
              {' '}
              &bull;
              {' '}
            </Typography>
          )}
        </Fragment>
      ))}
    </>
  );
}

function ExtendedConsumptionEmissions(props) {
  const { car } = props;
  const {
    i18n,
    t,
  } = useTranslation();
  let { fuelType } = car.attributes ?? car;
  const {
    isPluginHybrid,
    emissionsCombinedCo2,
    emissionsCombinedCo2Weighted,
    emissionsDischargedCo2,
    emissionsCombinedCo2Class,
    emissionsCombinedCo2ClassWeighted,
    emissionsDischargedCo2Class,
    consumptionsWeightedCombinedFuel,
    consumptionsFuelCombined,
    consumptionsFuelCity,
    consumptionsFuelSuburban,
    consumptionsFuelRural,
    consumptionsFuelHighway,
    consumptionsWeightedCombinedPower,
    consumptionsPowerCombined,
    consumptionsPowerCity,
    consumptionsPowerSuburban,
    consumptionsPowerRural,
    consumptionsPowerHighway,
    consumptionsFuelWithDischargedBatteryCombined,
    consumptionsFuelWithDischargedBatteryCity,
    consumptionsFuelWithDischargedBatterySuburban,
    consumptionsFuelWithDischargedBatteryRural,
    consumptionsFuelWithDischargedBatteryHighway,
  } = car.attributes ?? car;
  const parts = [];

  if (isPluginHybrid) {
    fuelType = 'plugin-hybrid';
  }

  switch (fuelType) {
    case 'gasoline':
    case 'diesel':
    case 'lpg':
    case 'hybrid':
    case 'hybridDiesel':
      parts.push({
        label: 'Verbrauch kombiniert',
        value: (
          <span>
            <strong>
              {formatNumber(consumptionsFuelCombined, i18n.language)}
            </strong>
            {' '}
            l/100 km
          </span>
        ),
      });
      parts.push({
        key: 'energyLabel',
        label: 'CO2-Klasse auf Grundlage der CO2-Emissionen kombiniert',
        value: (
          <EnergyLabel value={emissionsCombinedCo2Class} />
        ),
      });
      parts.push({
        label: 'CO2-Emissionen (kombiniert)',
        value: (
          <span>
            <strong>
              {emissionsCombinedCo2}
            </strong>
            {' '}
            g CO
            <sub><small>2</small></sub>
            /100 km
          </span>
        ),
      });
      break;
    case 'naturalGas':
      parts.push({
        label: 'Verbrauch kombiniert',
        value: (
          <span>
            <strong>
              {formatNumber(consumptionsFuelCombined, i18n.language)}
            </strong>
            {' '}
            kg/100 km
          </span>
        ),
      });
      parts.push({
        key: 'energyLabel',
        label: 'CO2-Klasse auf Grundlage der CO2-Emissionen kombiniert',
        value: (
          <EnergyLabel value={emissionsCombinedCo2Class} />
        ),
      });
      parts.push({
        label: 'CO2-Emissionen (kombiniert)',
        value: (
          <span>
            <strong>
              {emissionsCombinedCo2}
            </strong>
            {' '}
            g CO
            <sub><small>2</small></sub>
            /100 km
          </span>
        ),
      });
      break;
    case 'plugin-hybrid':
      parts.push({
        label: 'Verbrauch gewichtet, kombiniert',
        value: (
          <span>
            <strong>
              {formatNumber(consumptionsWeightedCombinedFuel, i18n.language)}
            </strong>
            {' '}
            l/100 km
          </span>
        ),
      });
      parts.push({
        label: 'Verbrauch bei entladener Batterie kombiniert',
        value: (
          <span>
            <strong>
              {formatNumber(consumptionsFuelWithDischargedBatteryCombined, i18n.language)}
            </strong>
            {' '}
            l/100 km
          </span>
        ),
      });
      parts.push({
        key: 'energyLabel',
        label: 'CO2-Klasse auf Grundlage der CO2-Emissionen gewichtet, kombiniert',
        value: (
          <EnergyLabel value={emissionsCombinedCo2ClassWeighted} />
        ),
      });
      parts.push({
        key: 'energyLabel',
        label: 'CO2-Klasse auf Grundlage der CO2-Emissionen bei entladener Batterie',
        value: (
          <EnergyLabel value={emissionsDischargedCo2Class} />
        ),
      });
      parts.push({
        label: 'Stromverbrauch gewichtet, kombiniert',
        value: (
          <span>
            <strong>
              {formatNumber(consumptionsWeightedCombinedPower, i18n.language)}
            </strong>
            {' '}
            kWh/100 km
          </span>
        ),
      });
      parts.push({
        label: 'CO2-Emissionen (gewichtet, kombiniert)',
        value: (
          <span>
            <strong>
              {emissionsCombinedCo2Weighted}
            </strong>
            {' '}
            g CO
            <sub><small>2</small></sub>
            /100 km
          </span>
        ),
      });
      break;
    case 'electric':
      parts.push({
        label: 'Stromverbrauch kombiniert',
        value: (
          <span>
            <strong>
              {formatNumber(consumptionsPowerCombined, i18n.language)}
            </strong>
            {' '}
            kWh/100 km
          </span>
        ),
      });
      parts.push({
        key: 'energyLabel',
        label: 'CO2-Klasse auf Grundlage der CO2-Emissionen kombiniert',
        value: (
          <EnergyLabel value={emissionsCombinedCo2Class} />
        ),
      });
      parts.push({
        label: 'CO2-Emissionen (kombiniert)',
        value: (
          <span>
            <strong>
              {emissionsCombinedCo2}
            </strong>
            {' '}
            g CO
            <sub><small>2</small></sub>
            /100 km
          </span>
        ),
      });
      break;
    case 'hydrogen':
      parts.push({
        label: 'Verbrauch kombiniert',
        value: (
          <span>
            <strong>
              {formatNumber(consumptionsFuelCombined, i18n.language)}
            </strong>
            {' '}
            kg/100 km
          </span>
        ),
      });
      parts.push({
        key: 'energyLabel',
        label: 'CO2-Klasse auf Grundlage der CO2-Emissionen kombiniert',
        value: (
          <EnergyLabel value={emissionsCombinedCo2Class} />
        ),
      });
      parts.push({
        label: 'CO2-Emissionen (kombiniert)',
        value: (
          <span>
            <strong>
              {emissionsCombinedCo2}
            </strong>
            {' '}
            g CO
            <sub><small>2</small></sub>
            /100 km
          </span>
        ),
      });
      break;
    default:
      break;
  }

  const energyLabels = parts.filter((part) => part.key);

  if (parts.length === 0) {
    return null;
  }

  return (
    <Paper
      sx={{
        bgcolor: 'quaternary.main',
        p: 1,
        border: 'none',
      }}
      variant="outlined"
    >
      <Typography component="div" sx={{ fontWeight: '700', mb: 1 }} variant="suntitle2">
        {t('pages.vehiclefleetapp.slug.tabs.emissionData')}
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={COLUM_SETTINGS}>
          {parts.filter((part) => !part.key)
            .map(({
              label,
              value,
            }) => (
              <Box key={label} mb={2}>
                <Typography component="div" variant="caption">
                  {`${label}:`}
                </Typography>
                <Typography component="div" variant="caption">
                  {value}
                  <sup><small>I.</small></sup>
                </Typography>
              </Box>
            ))}
        </Grid2>
        {energyLabels.map(({
          label,
          value,
        }) => (
          <Fragment key={label}>
            <Grid2 size={COLUM_SETTINGS}>
              <Typography component="div" variant="caption">
                {`${label}:`}
              </Typography>
              <Typography component="div" variant="caption">
                {value}
                <sup><small>I.</small></sup>
              </Typography>
            </Grid2>
          </Fragment>
        ))}
      </Grid2>
    </Paper>
  );
}

function EnergyLabel({ value }) {
  return (
    <NextImage
      alt={value}
      height={140}
      src={`/images/energy-label/${value.toLowerCase() || 'g'}.svg`}
      style={{ display: 'block' }}
      width={140}
    />
  );
}

const COLUM_SETTINGS = {
  xs: 6,
};

function formatNumber(value, locale) {
  return value?.toLocaleString(locale);
}

export function isEmissionsDisplayRequired(car) {
  // - ads with condition “NEW”
  // OR
  // - ads with condition “USED” AND (mileage <= 1000 km OR firstRegistration <= 8 months ago)
  //
  // As we only have the first registration month, we calculate firstRegistration month + 8 months to determine if the data is required.
  // Example: First registration 01/2024: Data is required for this listing even if the current month is September (09).
  const {
    isNewCar,
    initialRegistrationDate,
    mileage,
  } = car.attributes ?? car;

  if (isNewCar) {
    return true;
  }

  if (mileage <= 1000) {
    return true;
  }

  // noinspection RedundantIfStatementJS
  if (initialRegistrationDate
    && DateTime.fromISO(initialRegistrationDate) >= DateTime.now()
      .minus({ months: 9 })) {
    return true;
  }

  return false;
}

/*
Verbrennungsmotor (Benzin/Diesel/LPG)   5,5 l/100km (komb.), 125.0 g CO₂/km (komb.), CO₂-Klasse D (komb.)
Verbrennungsmotor (Erdgas)
Plug-In-Hybrid (Benzin/Diesel)
Elektromotor (Strom)                    17,0 kWh/100km (komb.), CO₂-Klasse A (komb.)
Brennstoffzelle (Wasserstoff)
 */

export const GRAPHQL_REQUIRED_PROPS_FOR_CONSUMPTION = `
isPluginHybrid
emissionsCombinedCo2
emissionsCombinedCo2Weighted
emissionsDischargedCo2
emissionsCombinedCo2Class
emissionsCombinedCo2ClassWeighted
emissionsDischargedCo2Class
consumptionsWeightedCombinedFuel
consumptionsFuelCombined
consumptionsFuelCity
consumptionsFuelSuburban
consumptionsFuelRural
consumptionsFuelHighway
consumptionsWeightedCombinedPower
consumptionsPowerCombined
consumptionsPowerCity
consumptionsPowerSuburban
consumptionsPowerRural
consumptionsPowerHighway
consumptionsFuelWithDischargedBatteryCombined
consumptionsFuelWithDischargedBatteryCity
consumptionsFuelWithDischargedBatterySuburban
consumptionsFuelWithDischargedBatteryRural
consumptionsFuelWithDischargedBatteryHighway
`;
