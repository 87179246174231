import { Box, Typography } from '@mui/material';

export default function LeasingConditionsNotice({ text, ...otherProps }) {
  return (
    <Box {...otherProps}>
      <Typography component="div" sx={{ color: 'grey.700' }} variant="caption">
        <sup>II.</sup>
        {' '}
        {text}
      </Typography>
    </Box>
  );
}
