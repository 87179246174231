import { Box, Typography } from '@mui/material';

export default function RegulatoryNotice(props) {
  return (
    <Box {...props}>
      <Typography component="div" sx={{ color: 'grey.700' }} variant="caption">
        <sup>I.</sup>
        {' '}
        Die Informationen erfolgen gemäß der Pkw-Energieverbrauchskennzeichnungsverordnung.
        Die angegebenen Werte wurden nach dem vorgeschriebenen Messverfahren WLTP
        (Worldwide Harmonised Light-Duty Vehicles Test Procedure) ermittelt. Der
        Kraftstoffverbrauch und der CO₂-Ausstoß eines Pkw sind nicht nur von der
        effizienten Ausnutzung des Kraftstoffs durch den Pkw, sondern auch vom Fahrstil
        und anderen nichttechnischen Faktoren abhängig. CO₂ ist das für die Erderwärmung
        hauptverantwortliche Treibhausgas. Ein Leitfaden über den Kraftstoffverbrauch und
        die CO₂-Emissionen aller in Deutschland angebotenen neuen Pkw-Modelle ist
        unentgeltlich einsehbar an jedem Verkaufsort in Deutschland, an dem neue Pkw
        ausgestellt oder angeboten werden. Der Leitfaden ist auch hier abrufbar:
        {' '}
        <a href="https://www.dat.de/co2/" target="_blank">www.dat.de/co2/</a>
      </Typography>
    </Box>
  );
}
